import { createContext, useContext, useState } from "react";

export const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [authUser, setauthUser] = useState(
    localStorage.getItem("accessToken") || null
  );
  const [authToken, setauthToken] = useState(
    localStorage.getItem("token") || null
  );
  const [authrepoName, setAuthRepoName] = useState();
//   console.log("from context", authrepoName);
  return (
    <AuthContext.Provider
      value={{ authUser, setauthUser, authrepoName, setAuthRepoName, authToken, setauthToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};
