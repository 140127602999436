import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import ds from "../assets/ds.png";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { RiGitRepositoryLine } from "react-icons/ri";
import { Octokit } from "@octokit/core";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import Footer from "../components/Footer";
import { Buffer } from "buffer";

export default function Dashboard() {
  const [userData, setUserData] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4; // Number of items to display per page
  const navigate = useNavigate();
  const [repoStatus, setRepoStatus] = useState(null);

  useEffect(() => {
    async function getUserData() {
      try {
        const response = await axios.get(
          "https://100daysofcoding.com/api/auth/getUserData",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        localStorage.setItem("user", JSON.stringify(response.data));
        setUserData(response.data);
      } catch (error) {
        console.log("Error fetching user data:", error.response.data);
      }
    }
    getUserData();
  }, []);

  const handleSolveClick = (problem) => {
    navigate("/solve", { state: { problem } });
  };

  const handleCreateRepo = async () => {
    const octokit = new Octokit({
      auth: localStorage.getItem("token"),
    });

    const readmeContent = `# 100 Days of Coding Challenge

Welcome to the 100 Days of Coding Challenge! This repository is designed to help you improve your coding skills by solving coding problems every day for 100 days.

## Getting Started

### Login with GitHub:
1. Visit the website of 100DaysofCodingChallenge
2. Click on the "Login with GitHub" button.
3. You'll be redirected to the GitHub login page.
4. Enter your GitHub credentials and authorize the application to access your account.

### Create Repository:
1. After successfully logging in, you'll see a "Create Repo" button.
2. Click on the "Create Repo" button.
3. This will create a new repository named "100DaysofCodingChallenge" in your GitHub profile.
4. The repository will be used for maintaining your coding practice.

### Solve Questions:
1. Once the repository is created, you'll be able to access a list of coding questions.
2. Each question will have a "Solve Problem" button.
3. Click on the "Solve Problem" button corresponding to the question you want to solve.
4. You'll be directed to a page where you can write and test your code to solve the problem.

### Commit Your Code:
1. After solving the question, you can commit your code.
2. Below the code editor, you'll find a "Commit" button.
3. Click on the "Commit" button to commit your code changes.
4. This will push your code to the GitHub repository created earlier.
5. You can provide a commit message describing your changes.

### Repeat:
- Repeat steps 3 and 4 for each question you want to solve.
- You can access the list of questions from the main dashboard.
- Solve as many questions as you like and commit your solutions to your GitHub repository.

    `;

    try {
      await octokit.request("POST /user/repos", {
        name: "100DaysofCodingChallenge",
        description:
          "Take the 100 Days of Coding Challenge and level up your programming skills! Join a vibrant community, commit to daily coding, and see your abilities soar",
        homepage: "https://100daysofcoding.com",
        private: false,
        is_template: false,
        auto_init: true,
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      });

      const user = JSON.parse(localStorage.getItem("user"));
      // console.log(user);
      let sha;
      try {
        const {
          data: { sha: existingSha },
        } = await octokit.request("GET /repos/{owner}/{repo}/contents/{path}", {
          owner: user.login,
          repo: "100DaysofCodingChallenge",
          path: "README" + ".md",
        });
        sha = existingSha;
      } catch (error) {
        if (error.response.status !== 404) {
          throw error;
        }
      }
      await octokit.request("PUT /repos/{owner}/{repo}/contents/{path}", {
        owner: user.login,
        repo: "100DaysofCodingChallenge",
        path: "README" + ".md",
        message: `readme`,
        content: Buffer.from(readmeContent).toString("base64"),
        sha: sha,
        author: {
          name: user.name,
          email: "100daysofcoding@mgail.com",
        },
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      });

      toast.success("Repo Created Successfully!!");
    } catch (error) {
      toast.error("Repo Already Exist");
      console.log(error);
    }
  };

  const handleFetchProblems = async () => {
    try {
      const response = await axios.get(
        "https://100daysofcoding.com/api/problems/slugs"
      );
      setData(response.data.questions);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchProblems();
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Slice the data to display only items for the current page
  const currentData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  useEffect(() => {
    const fetchRepoStatus = async () => {
      const octokit = new Octokit({
        auth: localStorage.getItem("token"),
      });
      const user = JSON.parse(localStorage.getItem("user"));
      try {
        const response = await octokit.request("GET /repos/{owner}/{repo}", {
          owner: user.login,
          repo: "100DaysofCodingChallenge",
          headers: {
            "X-GitHub-Api-Version": "2022-11-28",
          },
        });
        const status = response.status;
        // console.log("Repository status:", status);
        setRepoStatus(status);
      } catch (error) {
        console.log(error);
        // Handle error if needed
      }
    };

    fetchRepoStatus();
  }, []);

  return (
    <>
      <div className="bg-slate-200 min-h-screen">
        <div className="h-24">
          <Header data={userData} />
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner
              size="xl"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
            />
          </div>
        ) : (
          <div className="px-4 py-8 md:px-14">
            <div className="mb-8 flex justify-between items-center">
              <div className="flex items-center">
                <img
                  src={ds}
                  alt="Data Structures"
                  className="w-12 h-12 mr-3"
                />
                <div>
                  <h1 className="text-xl font-bold">Data Structures</h1>
                  <div className="mt-2">
                    <p className="text-gray-600 text-lg">100 Problems</p>
                  </div>
                </div>
              </div>
              {repoStatus === 200 ? null : (
                <button
                  onClick={handleCreateRepo}
                  className="p-2 bg-green-600 hover:scale-105 hover:duration-150 text-white rounded flex justify-around items-center"
                >
                  <RiGitRepositoryLine />
                  Create Repo
                </button>
              )}
            </div>
            <div className="overflow-x-auto">
              <h1 className="text-xl font-bold mb-4">Problems</h1>
              <ul>
                {currentData.map((question, index) => (
                  <li key={index} className="border-b py-4">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col">
                        <h2 className="text-lg font-semibold">
                          {question.questionFrontendId}. {question.title}
                        </h2>
                        <div className="flex mt-1">
                          <span className="text-sm text-gray-500">
                            Level: {question.difficulty}
                          </span>
                          <span className="text-sm text-gray-500 ml-4">
                            Accuracy: {Math.round(question.acRate)}
                          </span>
                        </div>
                      </div>
                      <button
                        onClick={() => handleSolveClick(question)}
                        className="text-blue-600 hover:text-blue-700 hover:underline text-sm md:text-base"
                      >
                        SOLVE PROBLEM
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <ReactPaginate
              pageCount={Math.ceil(data.length / itemsPerPage)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination flex justify-center mt-4"}
              activeClassName={"active"}
              pageLinkClassName={
                "px-2 py-1 mx-1 rounded bg-gray-300 text-gray-600"
              }
              previousLinkClassName={
                "px-2 py-1 mx-1 rounded bg-gray-300 text-gray-600"
              }
              nextLinkClassName={
                "px-2 py-1 mx-1 rounded bg-gray-300 text-gray-600"
              }
            />
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}
