import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";

export default function ProfilePage() {
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(user);
  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  return (
    <>
      <div className="h-24">
        <Header />
      </div>
      <div className="flex w-screen justify-center items-center h-screen bg-gray-100">
        <div className="w-8/12 pr-20 bg-gray-800 bg-opacity-90 backdrop-blur-lg overflow-hidden rounded-xl flex flex-col md:flex-row">
          <div className="p-4 md:p-8 flex flex-col justify-center md:w-3/4">
            <div className="bg-gradient-to-r from-blue-600 to-purple-600 p-2 md:p-4 rounded-full flex items-center justify-center mb-4 md:mb-8 animate-pulse">
              <div className="w-3 h-3 bg-red-500 rounded-full"></div>
              <div className="w-3 h-3 bg-yellow-500 rounded-full mx-2"></div>
              <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            </div>
            <div className="text-white text-center md:text-left">
              <h1 className="text-4xl font-semibold mb-6">{user.name}</h1>
              <h3 className="text-xl font-light mb-4">{user.login}</h3>
              <p className="text-lg mb-6">{user.bio}</p>
              <div className="flex justify-center md:justify-start mb-6">
                <div className="flex items-center mr-4">
                  <MdOutlinePeopleAlt className="text-2xl text-gray-300 mr-2" />
                  <span className="font-semibold text-lg">
                    {user.followers}
                  </span>
                  <span className="text-base text-gray-500 ml-1">
                    Followers
                  </span>
                </div>
                <div className="flex items-center">
                  <BsPersonFillAdd className="text-2xl text-gray-300 mr-2" />
                  <span className="font-semibold text-lg">
                    {user.following}
                  </span>
                  <span className="text-base text-gray-500 ml-1">
                    Following
                  </span>
                </div>
              </div>
              <div className="text-lg text-gray-300 mb-6">
                <p>Public Repos: {user.public_repos}</p>
                <p>Public Gist: {user.public_gists}</p>
                <p>Created At: {formatDate(user.created_at)}</p>
              </div>
              <h2 className="text-2xl font-semibold mb-4">
                Social Media links
              </h2>
              <ul className="text-lg text-gray-300">
                <li className="mb-4">
                  <strong>Email:</strong>{" "}
                  {user.email ? user.email : "Change your email to public"}
                </li>
                <li className="mb-4">
                  <strong>Company:</strong>{" "}
                  {user.company ? user.company : "Not Available"}
                </li>
                <li className="mb-4">
                  <strong>Location:</strong>{" "}
                  {user.location ? user.location : "Not Available"}
                </li>
                <li className="mb-4">
                  <strong>Twitter:</strong>{" "}
                  {user.twitter_username
                    ? user.twitter_username
                    : "Not Available"}
                </li>
              </ul>
            </div>
          </div>
          <div className="md:w-1/4 mt-20 flex justify-center md:justify-end">
            <img
              className="w-64 h-64 object-cover rounded-full shadow-2xl"
              src={user.avatar_url}
              alt="Profile"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
