import React, { useState } from "react";
import logo from "../assets/logo.svg";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";

export default function Header({ data }) {
  const { setauthUser, setauthToken } = useAuthContext();
  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        "https://100daysofcoding.com/api/auth/logout"
      );
      localStorage.clear();
      setauthUser(null);
      setauthToken(null);
      toast.success(response.data.success);
    } catch (error) {
      console.log("error from logout", error);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="fixed z-10 top-0 bg-slate-700 w-full h-24 flex justify-between items-center px-6">
      <div className="flex items-center">
        <Link to={"/dashboard"}>
          <div>
            <p className="text-5xl m-6">💯</p>
          </div>
        </Link>
        <ul className="text-white text-lg md:flex hidden">
          <li className="mr-6 cursor-pointer hover:text-gray-300 transition-colors duration-300">
            <Link to={"/dashboard"}>Practice</Link>
          </li>
          {/* <li className="mr-6 cursor-pointer hover:text-gray-300 transition-colors duration-300">
            <Link to={"/daily-challenges"}>Daily Challenges</Link>
          </li> */}
          <li className="cursor-pointer hover:text-gray-300 transition-colors duration-300">
            <a
              href="https://forms.gle/R49x2yUtKbzrcTvz6"
              rel="noopener"
              target="blank"
            >
              Feedback
            </a>
          </li>
        </ul>
      </div>
      <div className="flex items-center">
        <div className="relative md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <div className="block w-6 h-px bg-white mb-1"></div>
            <div className="block w-6 h-px bg-white mb-1"></div>
            <div className="block w-6 h-px bg-white"></div>
          </button>
          {showMenu && (
            <div className="absolute top-full right-0 mt-1 bg-gray-800 p-4 rounded-md">
              <ul className="text-white">
                <li className="mb-3">
                  <Link to={"/dashboard"} onClick={toggleMenu}>
                    Practice
                  </Link>
                </li>
                {/* <li className="mb-3">
                  <Link to={"/daily-challenges"} onClick={toggleMenu}>
                    Daily Challenges
                  </Link>
                </li> */}
                {/* <li className="mb-3">
                  <Link to={"/leaderboard"} onClick={toggleMenu}>
                    Leaderboard
                  </Link>
                </li> */}
                {data && data.avatar_url && (
                  <li className="mb-3">
                    <Link to={"/profile"} onClick={toggleMenu}>
                      Profile
                    </Link>
                  </li>
                )}
                <li>
                  <button
                    onClick={handleLogout}
                    className="text-white hover:text-gray-300 transition-colors duration-300"
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="hidden md:flex items-center">
          {data && data.avatar_url && (
            <Link to={"/profile"}>
              <button className="text-teal-700 rounded-full px-4 py-2  ml-4 transition-colors duration-300">
                <img
                  src={data.avatar_url}
                  className="rounded-full"
                  alt="img"
                  width={55}
                />
              </button>
            </Link>
          )}
          <button
            onClick={handleLogout}
            className="group flex items-center justify-start w-11 h-11 bg-red-600 rounded-full cursor-pointer relative overflow-hidden transition-all duration-200 shadow-lg hover:w-32 hover:rounded-lg active:translate-x-1 active:translate-y-1"
          >
            <div className="flex items-center justify-center w-full transition-all duration-300 group-hover:justify-start group-hover:px-3">
              <svg className="w-4 h-4" viewBox="0 0 512 512" fill="white">
                <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
              </svg>
            </div>
            <div className="absolute right-5 transform translate-x-full opacity-0 text-white text-lg font-semibold transition-all duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              Logout
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
