import { Octokit } from "@octokit/core";
import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import Editor from "@monaco-editor/react";
import { Buffer } from "buffer";
import toast from "react-hot-toast";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import axios from "axios";

export default function SolutionPage() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const { problem } = location.state;
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadOutput, setLoadOutput] = useState(false);
  const [code, setCode] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [output, setOutput] = useState("");
  const lastElementRef = useRef(null);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setCode(getDefaultCode(language));
  };

  const getDefaultCode = (language) => {
    // console.log(language);
    switch (language) {
      case "Javascript":
        return `console.log("Hello World");`;
      case "Python":
        return `print("Hello World")`;
      case "C":
        return `#include <stdio.h>\n\nint main() {\n    printf("Hello World");\n    return 0;\n}`;
      case "Cpp":
        return `#include <iostream>\nusing namespace std;\n\nint main() {\n    cout << "Hello World";\n    return 0;\n}`;
      case "Java":
        return `public class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello World");\n    }\n}`;
      case "Bash":
        return `echo "Hello World"`;
      default:
        return "";
    }
  };
  function getCurrentISTDateTime() {
    // Get current date and time in UTC
    var currentDateTimeUTC = new Date();

    // Convert UTC to IST (UTC+5:30)
    var ISTOffset = 1440; // Offset in minutes
    var ISTTime = new Date(currentDateTimeUTC.getTime() + ISTOffset * 60000);

    // Format the date and time for IST
    var options = {
      timeZone: "Asia/Kolkata",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    return ISTTime.toLocaleString("en-IN", options);
  }

  // Example usage
  // console.log(getCurrentISTDateTime());

  const version = {
    javascript: "18.15.0",
    c: "10.2.0",
    cpp: "10.2.0",
    python: "3.10.0",
    java: "15.0.2",
    bash: "5.2.0",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://100daysofcoding.com/api/problems/${problem.titleSlug}`
        );
        setContent(response.data.content);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [problem.titleSlug]);

  const API = axios.create({
    baseURL: "https://emkc.org/api/v2/piston",
  });

  const handleRun = async () => {
    try {
      setLoadOutput(true);
      const response = await API.post("/execute", {
        language: selectedLanguage.toLowerCase(),
        version: version[selectedLanguage.toLowerCase()],
        files: [
          {
            content: code,
          },
        ],
      });
      setOutput(response.data.run.output);
      setLoadOutput(false);
      if (lastElementRef.current) {
        lastElementRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error("Error executing code:", error);
    }
  };

  const handleCommit = async (title) => {
    const octokit = new Octokit({
      auth: localStorage.getItem("token"),
    });
    const fileContent = Buffer.from(code).toString("base64");

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      console.log(user);
      let sha;
      try {
        const {
          data: { sha: existingSha },
        } = await octokit.request("GET /repos/{owner}/{repo}/contents/{path}", {
          owner: user.login,
          repo: "100DaysofCodingChallenge",
          path: title + ".txt",
        });
        sha = existingSha;
      } catch (error) {
        if (error.response.status !== 404) {
          throw error;
        }
      }

      const response = await octokit.request(
        "PUT /repos/{owner}/{repo}/contents/{path}",
        {
          owner: user.login,
          repo: "100DaysofCodingChallenge",
          path: title + ".txt",
          message: `Updated for ${title} ${getCurrentISTDateTime()}`,
          content: fileContent,
          sha: sha,
          committer: {
            name: user.name,
            email: "100daysofcoding@gmail.com",
          },
          headers: {
            "X-GitHub-Api-Version": "2022-11-28",
          },
        }
      );
      toast.success("Commit Success");
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-slate-200 min-h-screen">
      <div className="w-full h-24">
        <Header data={userData} />
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start p-8">
        <div className="flex flex-col w-full md:w-1/2 mb-4 md:mb-0">
          <h1 className="m-3 text-xl font-semibold">{problem.title}</h1>
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </div>
          ) : (
            <div
              className="bg-white rounded-lg shadow-lg p-8 max-w-xl mr-8 overflow-scroll"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </div>
        <div className="w-auto h-auto md:w-1/2 flex flex-col">
          <div className="border border-gray-300 rounded-lg overflow-hidden flex-1">
            <div className="h-1/2">
              <h1 className="m-2 text-lg font-semibold">Code Editor</h1>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  className="m-2"
                >
                  {selectedLanguage ? selectedLanguage : "Select Language"}
                </MenuButton>
                <MenuList>
                  {["Javascript", "C", "Cpp", "Java", "Python", "Bash"].map(
                    (lang) => (
                      <MenuItem
                        key={lang}
                        onClick={() => handleLanguageSelect(lang)}
                      >
                        {lang}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </Menu>
              <div className="h-96" style={{ width: "100%" }}>
                {" "}
                <Editor
                  height="100%"
                  language={selectedLanguage.toLowerCase()}
                  value={code}
                  onChange={(value, event) => setCode(value)}
                />
              </div>
            </div>
            <div className="flex justify-end p-4">
              <div ref={lastElementRef}></div>
              <button
                className="bg-teal-600 hover:bg-teal-700 text-white px-4 py-2 rounded mr-4"
                onClick={handleRun}
              >
                Run
              </button>
              <button
                className="bg-slate-500 hover:bg-slate-600 text-white px-4 py-2 rounded"
                onClick={() => handleCommit(problem.titleSlug)}
              >
                Commit
              </button>
            </div>
          </div>
          {/* Output Box */}
          <div className="bg-gray-800 text-white p-4 overflow-y-auto mt-4">
            <h2 className="text-lg font-semibold mb-2">Output:</h2>
            {loadOutput ? "Running..." : <pre>{output}</pre>}
          </div>
        </div>
      </div>
    </div>
  );
}
