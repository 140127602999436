import React from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-slate-800 text-white py-4">
      <div className="max-w-screen-lg mx-auto px-4 flex flex-col items-center justify-between h-20 sm:flex-row text-center sm:text-left">
        <div>
          <h1 className="text-lg font-semibold">Version V1.0.33 🚀</h1>
          <p>Enjoying Chai from Hyderabad ☕❤️</p>
        </div>
        <div className="mt-2 sm:mt-0">
          <p className="text-sm">© {currentYear} All rights reserved. 📅</p>
        </div>
      </div>
    </footer>
  );
}