import React, { useEffect, useState } from "react";
import { Octokit } from "@octokit/core";
import { useAuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

export default function TokenPage() {
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const { setauthToken } = useAuthContext();
  const octokit = new Octokit({
    auth: token,
  });

  useEffect(() => {
    const userData = localStorage.getItem("token");

    if (userData) {

      navigate("/dashboard")
    } else {
      navigate("/")

    }
  }, [navigate])

  const handleVerify = async () => {
    try {
      const response = await octokit.request("POST /gists", {
        description: "100DaysofCodingChallenge",
        public: false,
        files: {
          "100DaysofCodingChallenge.md": {
            content: `
1. **Login with GitHub**:

   - Visit the website of 100DaysofCodingChallenge.
   - Click on the "Login with GitHub" button.
   - You'll be redirected to the GitHub login page.
   - Enter your GitHub credentials and authorize the application to access your account.

2. **Create Repository**:

   - After successfully logging in, you'll see a "Create Repo" button.
   - Click on the "Create Repo" button.
   - This will create a new repository named "100DaysofCodingChallenge" in your GitHub profile.
   - The repository will be used for maintaining your coding practice.

3. **Solve Questions**:

   - Once the repository is created, you'll be able to access a list of coding questions.
   - Each question will have a "Solve Problem" button.
   - Click on the "Solve Problem" button corresponding to the question you want to solve.
   - You'll be directed to a page where you can write and test your code to solve the problem.

4. **Commit Your Code**:

   - After solving the question, you can commit your code.
   - Below the code editor, you'll find a "Commit" button.
   - Click on the "Commit" button to commit your code changes.
   - This will push your code to the GitHub repository created earlier.
   - You can provide a commit message describing your changes.

5. **Repeat**:

   - Repeat steps 3 and 4 for each question you want to solve.
   - You can access the list of questions from the main dashboard.
   - Solve as many questions as you like and commit your solutions to your GitHub repository.
`,
          },
        },
        headers: {
          "X-GitHub-Api-Version": "2022-11-28",
        },
      });
      console.log(response);
      toast.success("Account Verified");
      localStorage.setItem("token", token);
      setauthToken(token);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  return (
    <div className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 min-h-screen flex flex-col justify-center items-center">
      <div className="max-w-md bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center mb-6">
          Generate Your Token
        </h1>
        <ol className="list-decimal pl-6 mb-8">
          <li className="mb-2">
            Click on the{" "}
            <a
              href="https://github.com/settings/tokens"
              className="text-blue-700 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Generate Token link
            </a>
          </li>
          <li className="mb-2">
            After visiting the page, click on "Generate new token".
          </li>
          <li className="mb-2">Click on "Generate new token (classic)".</li>
          <li className="mb-2">Confirm access by entering your password.</li>
          <li className="mb-2">Name your token and set its expiration.</li>
          <li className="mb-2">
            Select all the scopes; don't forget any of them.
          </li>
          <li className="mb-2">Click on the "Generate token" button.</li>
          <li className="mb-2">
            Copy the token and paste it below the input field, then click on
            "Verify".
          </li>
          <li className="mb-2">
            Remember your access token (Save it somewhere)
          </li>
        </ol>
        <div className="mb-6">
          <p className="text-lg font-semibold mb-2 text-center">
            Paste your token here:
          </p>
          <input
            type="text"
            className="border border-gray-300 rounded-md px-4 py-2 w-full mb-4"
            placeholder="Your token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <Link to="/dashboard">
            {" "}
            <button
              onClick={handleVerify}
              className="bg-blue-500 font-semibold text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600 transition-colors w-full"
            >
              Verify
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
