import axios from "axios";
import toast from "react-hot-toast";
import { FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { motion } from "framer-motion";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useEffect, useMemo, useState } from "react";

// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

const CLIENT_ID = "d5d25007ff14ccc14dd1";

const ParticlesComponent = (props) => {
  const [init, setInit] = useState(false);
  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      // background: {
      //   color: {
      //     value: "#1E2F97",
      //   },
      // },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "repulse",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
        modes: {
          push: {
            distance: 200,
            duration: 15,
          },
          grab: {
            distance: 150,
          },
        },
      },
      particles: {
        color: {
          value: "#000000",
        },
        links: {
          color: "#000000",
          distance: 150,
          enable: true,
          opacity: 0.3,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 150,
        },
        opacity: {
          value: 1.0,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  return <Particles id={props.id} init={particlesLoaded} options={options} />;
};

export default function Login() {
  const { setauthUser } = useAuthContext();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParam = new URLSearchParams(queryString);
    const codeParam = urlParam.get("code");

    if (codeParam && localStorage.getItem("accessToken") === null) {
      async function getAccessToken() {
        try {
          const response = await axios.get(
            `https://100daysofcoding.com/api/auth/getAccessToken?code=${codeParam}`
          );

          if (response.data.access_token) {
            localStorage.setItem("accessToken", response.data.access_token);
            setauthUser(response.data.access_token);

            if (response.status === 201) {
              <Link to="/" />;
            }
          }
          toast.success("Login Successfull");
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
      getAccessToken();
    }
  }, [setauthUser]);

  function loginWithGithub() {
    console.log("hanzala");
    window.location.assign(
      `https://github.com/login/oauth/authorize?client_id=${CLIENT_ID}`
    );
  }

  return (
    <>
      <ParticlesComponent id="tsparticles" />
      <div className="h-screen bg-main bg-no-repeat bg-cover bg-fixed">
        <nav className="flex justify-around items-center bg-transparent bg-opacity-75 pt-10">
          <div>
            <p className="text-5xl">💯</p>
          </div>
          <ul className="flex ">
            <li className="mr-10 ">
              <a
                href="#"
                className="text-black text-xl font-semibold hover:text-blue-400 transition duration-300"
              >
                Home
              </a>
            </li>
            {/* <li>
              <a
                href="https://www.linkedin.com/in/-hanzala-shaikh-"
                rel="noopener"
                className="text-black text-xl font-semibold hover:text-blue-400 transition duration-300"
              >
                Contact
              </a>
            </li> */}
          </ul>
        </nav>

        <div className="flex justify-center items-center mt-20  flex-col">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0 }}
            className="text-5xl font-bold mb-4 text-slate-900"
          >
            Welcome!
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
            className="md:text-4xl text-white font-semibold flex justify-center items-center flex-col"
          >
            
            <span >Join the{" "}100 Days of Coding Challenge</span>{" "}
            and enhance your coding skills!
          </motion.p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={loginWithGithub}
            className="mt-8 text-xl hover:border font-semibold w-64 px-6 py-4 bg-black bg-opacity-75 backdrop-filter backdrop-blur-md text-white rounded-full flex items-center justify-center transition duration-300 shadow-md"
          >
            <FaGithub className="mr-2" /> Login with GitHub
          </motion.button>
        </div>
      </div>

      <footer className="fixed bottom-0 md:text-lg left-0 w-full flex justify-center items-center h-24 py-4 bg-gradient-to-t from-white to-transparent bg-opacity-75 backdrop-filter backdrop-blur-lg text-center text-gray-700 shadow-lg">
        &copy; {new Date().getFullYear()} 100DaysofCodingChallenge. All rights
        reserved.
      </footer>
    </>
  );
}
