import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login.jsx";
import Dashboard from "./pages/Dashboard.jsx";
import { Toaster } from "react-hot-toast";
import { useAuthContext } from "./context/AuthContext.jsx";
import ProfilePage from "./pages/ProfilePage.jsx";
import SolutionPage from "./pages/SolutionPage.jsx";
import TokenPage from "./pages/TokenPage.jsx";
import DailyChallenge from "./pages/Dailychallenges.jsx";
import { isMobile } from "react-device-detect";
import mobile from "./assets/mobile.png"
export default function App() {
  const { authUser, authToken } = useAuthContext();

  if (isMobile) {
    return <div> <img src={mobile} alt="please use our website in desktop" /></div>;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={authUser ? <TokenPage /> : <Login />} />
        <Route
          path="/dashboard"
          element={authToken ? <Dashboard /> : <Login />}
        />
        <Route
          path="/profile"
          element={authUser ? <ProfilePage /> : <Login />}
        />
        <Route
          path="/solve"
          element={authUser ? <SolutionPage /> : <Login />}
        />
        <Route
          path="/daily-challenges"
          element={authUser ? <DailyChallenge /> : <Login />}
        />
      </Routes>
      <Toaster />
    </>
  );
}
