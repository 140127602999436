import React from "react";
import ConstructionImage from "../assets/work.png";
import Footer from "../components/Footer";

export default function Dailychallenges() {
  return (
    <>
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <img src={ConstructionImage} alt="Under Construction" className="mb-8" />
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        Site Under Development
      </h1>
      <p className="text-lg text-gray-600 mb-8">
        We're sorry for the inconvenience. Our site is currently under
        development.
      </p>
      <p className="text-lg text-gray-600 mb-8">Please check other options!</p>
    </div>
    <Footer />
    </>
  );
}
